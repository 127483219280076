<template>
    <b-card>
        <template #header>
            <h5>
                <b-badge variant="primary">Total de Links: <b>{{ pagination.total_rows }}</b></b-badge>
            </h5>
        </template>
        <b-form class="d-flex justify-content-end my-2">
            <b-col>
                <SelectContest
                    placeholder="Pesquisar por sorteio"
                    v-model="contestId"
                />
            </b-col>
        </b-form>
        <b-row>
            <b-col>
                <b-card>
                    <template #header>
                        <b-row>
                            <b-col>
                                <h5 class="text-uppercase">
                                    Links
                                </h5>
                            </b-col>
                            <b-col class="d-flex justify-content-end">
                                <b-button
                                    v-if="isFeatureEnabled('partner-links')"                                          
                                    variant="info"
                                    size="sm"
                                    @click.prevent="partnerLinksModal.openClosePartnerLinksModal()"
                                    :disabled="!contestId"
                                >
                                    <i class="mdi mdi-link-variant-plus"></i>
                                    Criar Link
                                </b-button>
                                <upgrade-trigger
                                    v-else
                                    title="Criar Link"
                                    :disabled="!contestId"
                                >
                                    <template v-slot:icon>
                                        <i class="mdi mdi-link-variant-plus"></i>
                                    </template>
                                </upgrade-trigger>
                            </b-col>
                        </b-row>
                    </template>
                    <b-row v-if="items.length && !loading">
                        <b-table
                            id="my-table"
                            :items="items"
                            :fields="colums"
                            responsive="sm"
                            hover
                        >
                            <template #cell(actions)="row">                                   
                                <b-button-group size="sm">
                                    <b-button
                                        variant="info"
                                        @click.prevent="copyToClipboard(row.item.link)"
                                    >
                                        <i class="mdi mdi-content-copy"></i>
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        @click.prevent="linkStatistics.getPartnerLinkStats(row.item.id)"
                                    >
                                        <i class="mdi mdi-chart-bar"></i>
                                    </b-button>
                                </b-button-group>
                            </template>
                        </b-table>
                        <b-pagination
                            v-model="pagination.page"
                            :total-rows="pagination.total_rows"
                            :per-page="pagination.per_page"
                            :disabled="loading"
                            class="mt-2"
                            aria-controls="my-table"
                            align="center"
                            size="sm"
                        />
                    </b-row>
                    <b-row v-else class="text-center mt-2">
                        <div v-if="loading" class="text-center my-2">
                            <b-spinner class="align-middle" />
                        </div>
                        <b-alert v-else show variant="warning" class="text-center"><b>Sem Resultados</b></b-alert>
                    </b-row>
                </b-card>
            </b-col>
            <b-col>
                <PartnerLinksStatistics
                    ref="linkStatistics"
                />
            </b-col>     
        </b-row>
    </b-card>
    <PartnerLinksModal
        ref="partnerLinksModal"
        :contestId="contestId?.value"
        @submit="getContestPartnerLinks()"
    />
</template>

<script>
import { ref, reactive, watch } from "vue"
import { getPartnerLinks } from "../../services/partner-links/api"
import { formatDatetimeBR } from "../../../utils/date"
import SelectContest from '@/components/select-contest'
import { Toasts } from "../../utils/toast-alerts";
import PartnerLinksStatistics from "./PartnerLinksStatistics.vue";
import PartnerLinksModal from './PartnerLinksModal.vue';

export default {
    components: {
        SelectContest,
        PartnerLinksStatistics,
        PartnerLinksModal
    },
    setup() {
        const loading = ref(false)
        const loadingStatistics = ref(false)
        const linkStatistics = ref(null)
        const pagination = reactive({
            total_rows: 0,
            per_page: 10,
            page: 1
        })
        const contestId = ref(null);
        const items = ref([])
        const colums = ref([
            {
                key: 'name',
                label: 'Nome',
            },
            {
                key: 'link',
                label: 'Link',
            },
            {
                key: 'actions',
                label: 'Ações',
            }
        ])
        const partnerLinksModal = ref(null)
        const exportDisabled = ref(false)

        async function copyToClipboard(textToCopy) {
            try {
                // await navigator.clipboard.writeText(copiedText)
                if (navigator.clipboard && window.isSecureContext) {
                await navigator.clipboard.writeText(textToCopy)
                } else {
                // text area method
                let textArea = document.createElement("textarea")
                textArea.value = textToCopy
                // make the textarea out of viewport
                textArea.style.position = "fixed"
                textArea.style.left = "-999999px"
                textArea.style.top = "-999999px"
                document.body.appendChild(textArea)
                textArea.focus()
                textArea.select()
                new Promise((res, rej) => {
                    document.execCommand('copy') ? res() : rej()
                    textArea.remove()
                })
                }
                Toasts('success', 'Copiado para a área de transferência')
            } catch (error) {
                Toasts('error', 'Falha ao copiar para a área de transferência')
            }
        }

        const getContestPartnerLinks = () => {
            loading.value = true
            let contest = null

            if (contestId.value) {
                contest = contestId.value['value']
            }
            const params = {
                ...pagination,
                contest_id: contest,
            }

            getPartnerLinks(params).then((data) => {
                items.value = data.data
                pagination.total_rows = data.total
                loading.value = false
            }).catch(() => {
                Toasts('error', 'Falha ao carregar os links de parceiros')
                loading.value = false;
            }).finally(() => {
                loading.value = false;
            })
        }

        watch(contestId, () => {
                linkStatistics.value.partnerLinkStats = ''
                // partnerLinksModal.value 
                getContestPartnerLinks()
            }
        )

        watch(pagination, () => {
                linkStatistics.value.partnerLinkStats = ''
                // partnerLinksModal.value 
                getContestPartnerLinks()
            }
        )

        return {
            loading,
            loadingStatistics,
            copyToClipboard,
            linkStatistics,
            getContestPartnerLinks,
            formatDatetimeBR,
            pagination,
            exportDisabled,
            colums,
            items,
            contestId,
            partnerLinksModal
        }
    },
}

</script>