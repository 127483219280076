<template>
    <b-card>
        <template #header>
            <h5 class="text-uppercase">
                Estatísticas
            </h5>
        </template>
        <b-row class="g-2" v-if="partnerLinkStats && !loadingStatistics">
            <b-col sm="6">
                <b>Parceiro:</b>
            </b-col>
            <b-col xs="12" sm="6">
                <strong>
                    {{ partnerLinkStats.linkName }}
                </strong>
            </b-col>
        
            <b-col sm="6">
                <b>Link Completo</b>
            </b-col>
            <b-col xs="12" sm="6">
                <b-link
                    :href="partnerLinkStats.long_url"
                    target="_blank"
                >
                    {{ partnerLinkStats.long_url }}
                </b-link>
            </b-col>
        
            <b-col sm="6">
                <b>Referenciador:</b>
            </b-col>
            <b-col xs="12" sm="6">
                {{ partnerLinkStats.referrer }}
            </b-col>
        
            <b-col sm="6">
                <b>Clicks:</b>
            </b-col>
            <b-col xs="12" sm="6">
                <b-badge variant="success">{{ partnerLinkStats.total_clicks ?? 0 }}</b-badge>
            </b-col>
        
            <b-col sm="6">
                <b>Pedidos:</b>
            </b-col>
            <b-col xs="12" sm="6">
                <b-badge variant="success">{{ partnerLinkStats.total ?? 0 }}</b-badge>
            </b-col>
        
            <b-col sm="6">
                <b>Montante:</b>
            </b-col>
            <b-col xs="12" sm="6">
                <b-badge variant="success">{{ $n(parseFloat(partnerLinkStats.amount ?? 0), 'currency') }}</b-badge>
            </b-col>
        </b-row>
        <b-row v-else class="text-center mt-2">
            <div v-if="loadingStatistics" class="text-center my-2">
                <b-spinner class="align-middle" />
            </div>
            <b-alert v-else show variant="warning" class="text-center"><b>Nenhum Link Selecionado</b></b-alert>
        </b-row>
    </b-card>
</template>

<script>
import { ref } from "vue"
import { getLinkStats } from "../../services/partner-links/api"
import { Toasts } from "../../utils/toast-alerts";

export default {
    setup(props, { expose }) {
        const loadingStatistics = ref(false)
        const partnerLinkStats = ref(null)

        const getPartnerLinkStats = (linkId) => {
            loadingStatistics.value = true

            getLinkStats(linkId).then((data) => {
                partnerLinkStats.value = data.data
                loadingStatistics.value = false
            }).catch(() => {
                Toasts('error', 'Falha ao carregar os links de parceiros')
                loadingStatistics.value = false;
            }).finally(() => {
                loadingStatistics.value = false;
            })
        }

        expose({
            getPartnerLinkStats,
            partnerLinkStats
        })

        return {
            loadingStatistics,
            getPartnerLinkStats,
            partnerLinkStats,
        }
    },
}

</script>