<template>
    <b-modal
        v-model="showModal"
        class="v-modal-custom"
        size="md"
        title="Criar Link de Parceiro"
        no-close-on-backdrop
    >
        <b-form>
            <label class="mx-1">Selecionar Parceiro</label>
            <v-select
                placeholder="Pesquisar parceiro"
                class="custom-select my-1"
                v-model="formData.partner_id"
                :options="partnerOptions"
                @open="fetchMyPartners"
                @search="fetchMyPartners"
            >
            </v-select>
            <b-row class="g-3">
                <b-form-checkbox
                    id="checkbox-1"
                    v-model="createPartner"
                    value="accepted"
                    class="mx-1"
                >
                    <label style="color:#32CD32">Não encontrei. Criar Parceiro</label>
                </b-form-checkbox>
            </b-row>
            <b-row v-if="createPartner">
                <label class="mx-1">Criar Parceiro</label>
                <b-col class="mx-1">
                <b-form-input
                    v-model="formData.name"
                    type="text"                    
                    id="partnerName"
                    placeholder="Nome do parceiro caso não o encontre acima"
                />
                </b-col>
                     
            </b-row>
        </b-form>
        <template #footer>
            <b-button size="sm" variant="danger" @click="openClosePartnerLinksModal()">
                <b-icon icon="x"></b-icon>
                Cancelar
            </b-button>
            <b-button size="sm" variant="success" type="submit" @click.prevent="submitPartnerLink()">
                <b-icon icon="check"></b-icon>
                Salvar
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import { ref, reactive, toRefs } from 'vue'
import debounce from '@/utils/debounce';
import { fetchPartners, createPartnerLink } from "../../services/partner-links/api";
import { Toasts } from "../../utils/toast-alerts";

export default {
    props:{
        contestId:{
            type: Number
        },
    },
    setup(props, { emit, expose })  {
        const partnerOptions = ref([])
        const { contestId } = toRefs(props)
        const loading = ref(false)
        const createPartner = ref(false)
        const formData = reactive({
            contest_id: '',
            partner_id: ''
        })

        const fetchMyPartners = debounce((search = '') => {
            fetchPartners({ name: search }).then((response) => {
                partnerOptions.value = response.data.map(({ id, name }) => ({
                    label: name,
                    value: id
                }))
            })
        }, 500)

        const showModal = ref(false)
        const openClosePartnerLinksModal = () =>{
            showModal.value = !showModal.value
        }

        async function submitPartnerLink(){
            if (formData.partner_id && formData.partner_id.value === undefined && formData.name === undefined) {
                Toasts('error', 'É necessário selecionar um parceiro ou enviar seu nome para cadastro')
                return
            }

            let partnerLinkToSave = {
                contest_id: contestId.value,
                name: formData.name
            }

            if (!createPartner.value) {
                partnerLinkToSave.partner_id = formData.partner_id.value
            }

            createPartnerLink(partnerLinkToSave).then(() => {
                Toasts('success', 'Link de parceiro criado com sucesso!')
                emit('submit')
                openClosePartnerLinksModal()
            }).catch ((e) => {
                const message = e?.response?.data?.errors?.join('; ') ?? 'Falha ao processar'
                Toasts('error', message)
            })
        }

        expose({
            openClosePartnerLinksModal
        })

        return {
            formData,
            submitPartnerLink,
            partnerOptions,
            createPartner,
            fetchMyPartners,
            loading,
            openClosePartnerLinksModal,
            showModal
        };
    }
}
</script>