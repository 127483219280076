import { axios } from '../../axios'

export const getPartnerLinks = (params = {}) => {
  return axios.get('/links', { params }).then(({ data }) => data)
}

export const createPartnerLink = (params = {}) => {
  return axios.post('/links/make-partner-link', { ...params })
    .then(({ data }) => data)
}

export const getLinkStats = (linkId) => {
  return axios.get(`/links/get-link-stats/${linkId}`, { }).then((data) => data)
}

export const fetchPartners = (params = {}) => {
  params.paginate = false
  params.select = 'id,name'
  return axios.get('/partners', { params }).then(({ data }) => data)
}
