<template>
    <Layout :pagetitle="'Links de Parceiros'">
        <PartnerLinks />
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PartnerLinks from "../../../components/partner-links/PartnerLinks.vue";

export default {
    components: {
        Layout,
        PartnerLinks,
    },
};
</script>
